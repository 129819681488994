<template>
  <div class="main">
    <div class="EmployeesAccountTitle flexAndCenter">
      <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("通知公告") }}</div>
    </div>
    <!--    状态切换与操作部分-->
    <el-tabs v-model="status_name" type="card" style="margin: 26px 26px 0" @tab-click="getList();checked=false;">
      <el-tab-pane :label="temporaryText" name="0"></el-tab-pane>
      <el-tab-pane :label="allText" name="2"></el-tab-pane>
      <el-tab-pane :label="offerText" name="1"></el-tab-pane>
    </el-tabs>
    <div class="informOperationContainer flexAndCenter">
      <div class="flexAndCenter checkedContainer">
        <input
            type="checkbox"
            style="margin:0"
            @change="allChiose"
            v-model="checked"
        />
        <div>{{ $fanyi("全选") }}</div>
      </div>
      <div class="deleteText" @click="deleteNotification">{{ $fanyi("删除") }}</div>
    </div>
    <div class="notificationContainer">
      <!-- 通知列表 -->
      <div class="Notification"
           v-for="(inFoItem, inFoIndex) in List"
           :class="{ read: inFoItem.statusName == '已读' }"
           :key="inFoIndex">
        <div class="flexAndCenter">
          <input
              v-model="inFoItem.checked"
              style="margin:0;"
              type="checkbox"
              @change="isAllChecked"
          />
          <img v-show="inFoItem.read_at == null" src="../../../../assets/unread.png"/>
          <p :style="inFoItem.read_at!=null?'margin-left:90px':'margin-left:50px'" class="NotificationTitle"
             @click="openDetail(inFoItem)">
            {{ inFoItem.title }}
          </p>
        </div>
        <div class="datetime">{{ inFoItem.datetime_start }}</div>
      </div>
    </div>
    <!--      删除通知提示-->
    <div class="holdOrderDialogVisibleContainer">
      <el-dialog
          :visible.sync="confirmDeleteDialogVisible"
          width="360px"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center>
        <div class="holdOrderContainer">
          <div class="flexAndCenter holdOrderHeaderContainer">
            <div class="icon">!</div>
            <div class="holdOrderHeader">
              {{ $fanyi('提示') }}
            </div>
          </div>
          <div class="holdOrderText">{{
              status === false ? $fanyi('是否确认删除选中的内容。') : $fanyi('当前选择的通知中，包含有未读的通知，是否确认删除选中的内容。')
            }}
          </div>
          <div slot="footer" class="dialog-footer"
               style="display: flex;padding-bottom:20px;align-items: center;justify-content: center">
            <el-button @click="confirmDeleteDialogVisible = false">{{ $fanyi('リセット') }}</el-button>
            <el-button type="primary" @click="deleteInFo">
              {{ $fanyi('确定') }}
            </el-button>
          </div>
        </div>
      </el-dialog>
      <!--      公告内容-->
      <el-dialog
          :visible.sync="showInformDialogVisible"
          width="700px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center>
        <InFormDetail ref="InFormDetail"/>
      </el-dialog>
    </div>
    <div>
    </div>
  </div>
</template>
<script>
import InFormDetail from "./InFormDetail";

export default {
  data() {
    return {
      checked: false,
      List: [],
      status_name: '',
      status: false,
      deleteArr: [],
      readItem: {},
      showInformDialogVisible: false,
      confirmDeleteDialogVisible: false,
      temporaryText: this.$fanyi('全部通知') + '（0）',
      allText: this.$fanyi('已读') + '（0）',
      offerText: this.$fanyi('未读') + '（0）',
    };
  },
  components: {InFormDetail},
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      this.List = [];
      if (this.status_name === 0) {
        this.status_name = '';
      }
      this.$api.noticeClientNoticeList({hasRead: this.status_name, page: 1, pageSize: 9999}).then((res) => {
        let count = 0;//已读数
        let count1 = 0;//未读数
        res.data.data.forEach((item) => {
          if (item.read_at != null) {
            count++
          }
          if (item.read_at == null) {
            count1++
          }
        })
        this.List = res.data.data;
        if (this.status_name == 0) {
          this.temporaryText = this.$fanyi('全部通知') + `（${res.data.data.length}）`;
          this.allText = this.$fanyi('已读') + `（${count}）`;
          this.offerText = this.$fanyi('未读') + `（${count1}）`;
        }
      });
    },
    // 删除通知
    deleteNotification() {
      this.status = false;
      this.List.forEach((item) => {
        if (item.checked == true) {
          if (item.read_at == null) {
            this.status = true;
          }
          this.deleteArr.push(item.id);
        }
      });
      if (this.deleteArr.length === 0) {
        this.$message.error(this.$fanyi("请至少选择一项通知"));
        return
      }
      this.confirmDeleteDialogVisible = true;
    },
    //删除选中通知
    deleteInFo() {
      this.$api.noticeBatchDelete({ids: this.deleteArr}).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi("操作失败"));
        this.$message.success(this.$fanyi("操作成功"));
        this.confirmDeleteDialogVisible = false;
        this.getList();
      });
    },
    // 标记已读
    beenRead(id) {
      this.$api.noticeReadNotice({id: id}).then((res) => {
        this.getList();
      });
    },
    // 打开详情弹窗
    openDetail(item) {
      if (item.read_at == null) {
        this.beenRead(item.id);
      }
      this.readItem = item;
      this.showInformDialogVisible = true;
      this.$forceUpdate();
    },
    // 全选
    allChiose() {
      this.List.forEach((item) => {
        item.checked = this.checked;
      });
    },
    //是否全选
    isAllChecked() {
      let count = 0;
      this.List.forEach((item) => {
        if (item.checked === true) {
          count++
        }
        this.checked = count === this.List.length;
      })
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

.dia {
  display: none;
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}

/deep/ .el-checkbox__input {
  height: 20px;

  input {
    width: 20px;
    height: 20px;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;

    &::after {
      left: 7px;
      top: 4px;
    }
  }
}

.main {
  min-height: 726px;
  background: #ffffff;
  //padding: 20px 20px 76px;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  /deep/ .el-tabs__header {
    margin: 0;
  }

  .informOperationContainer {
    height: 56px;
    margin: 0 26px;
    border-bottom: 1px solid #DFDFDF;

    .checkedContainer {
      padding-left: 20px;

      div {
        margin-left: 8px;
        font-size: 14px;
        color: #333333;
        margin-top: -2px;
      }
    }

    .deleteText {
      cursor: pointer;
      color: #B4272B;
      font-size: 14px;
      margin-left: 46px;
    }
  }

  .notificationContainer {
    margin: 0 26px;

    .Notification {
      height: 60px;
      border-bottom: #DFDFDF solid 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 20px;

      img {
        margin-left: 20px;
      }

      &.read {
        .circle {
          background: #cdcdcd;
        }

        .NotificationTitle {

          color: #666666;
        }
      }

      /deep/ .el-checkbox__input {
        height: 20px;

        input {
          width: 16px;
          height: 16px;
        }

        .el-checkbox__inner {
          width: 16px;
          height: 16px;

          &::after {
            left: 7px;
            top: 4px;
          }
        }
      }

      .circle {
        width: 8px;
        height: 8px;
        background: #ff0000;
        border-radius: 50%;
        margin: 0 6px 0 20px;
      }

      .NotificationTitle {
        font-size: 14px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .datetime {
        color: #999999;
        font-size: 14px;
      }
    }

    .Notification:last-child {
      border-bottom: none;
    }
  }
}

.holdOrderDialogVisibleContainer {
  /deep/ .el-dialog {
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/ .el-dialog__body, /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog__close {
    font-size: 19px;
    color: #B4272B;
    font-weight: bold;
  }


  .holdOrderContainer {
    .icon {
      width: 20px;
      height: 20px;
      background: #B4272B;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      margin-right: 6px;
    }

    .holdOrderHeaderContainer {
      height: 46px;
      padding-left: 26px;
      line-height: 46px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #DFDFDF;

      .holdOrderHeader {
        color: #333;
      }
    }

    .holdOrderText {
      color: #333333;
      font-weight: bold;
      text-align: center;
      margin: 40px 30px;
    }

    .dialog-footer {
      button {
        width: 120px;
        height: 34px;
        border-radius: 8px;
        padding: 0;
        font-size: 14px;
      }

      button:first-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }
}

.operation {
  position: absolute;
  bottom: 0;
  padding: 0 20px 0 17px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 1010px;
  background: #f0f0f0;
  left: 0;

  .deleteBtn {
    color: #182280;

    i {
      margin-right: 6px;
    }
  }
}
</style>
